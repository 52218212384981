import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import '../Styles/ScreenThirtyNine.css'

function ScreenThirtyNine() {
    return (
        <div className="screen_thirty_nine"><div className="pagination_div"><p className="pagination_style">39</p></div>
            <Player className="aR"  style={{width:'90vw'}} autoplay loop src="https://assets8.lottiefiles.com/packages/lf20_9bbvgqb8.json"></Player>
        </div>
    )
}

export default ScreenThirtyNine
