import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import title2 from '../../Assets/json/TITLE2.json';
import '../Styles/ScreenThirtyFour.css'

function ScreenThirtyFour() {
    return (
        <div id="screen_thirty_four" className="screen_thirty_four"><div className="pagination_div"><p className="pagination_style">34</p></div>
            <Player className="aR"  style={{width:'90vw'}} autoplay loop src={title2}></Player>
        </div>
    )
}

export default ScreenThirtyFour
