import React from 'react'
import '../Styles/ScreenFourteen.css'
import { Player } from '@lottiefiles/react-lottie-player';
function ScreenFourteen() {
    return (
        <div className="screen_fourteen" ><div className="pagination_div"><p className="pagination_style">15</p></div>
                   <Player  className="aR" style={{width:'90vw'}} autoplay loop src="https://assets10.lottiefiles.com/packages/lf20_x025raob.json"></Player>
        </div>
    )
}

export default ScreenFourteen