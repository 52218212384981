import React, {useEffect, useState} from 'react';
import '../Styles/IndexEleven.css'
import aglaCircle from '../../Assets/index/SVG/agla_circle.svg';
import ogbaeCircle from '../../Assets/index/SVG/ogbae_circle.svg';
import romanceCircle from '../../Assets/index/SVG/romance_circle.svg';
import covidCircle from '../../Assets/index/SVG/covid_circle.svg';
import applyCircle from '../../Assets/index/SVG/apply_circle.svg';
import alexaCircle from '../../Assets/index/SVG/alexa_circle.svg';
import volumeCircle from '../../Assets/index/SVG/volume_circle.svg';
import page11bg from '../../Assets/index/indexmainbg-03-03.png';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { propTypes } from 'react-bootstrap/esm/Image';

function IndexEleven(props) {
    const [screenName,setScreenName] = useState('')
    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
  
    function handleShow(breakpoint) {
      setFullscreen(breakpoint);
      setShow(true);
    }
    return (
        <div className="screen_thirteen" ><div className="pagination_div"><p className="pagination_style">12</p></div>
            <div className="relative_sc">
                <div className="main_background_image">
                    <img style={{width:'90vw'}} src={page11bg}></img>
                </div>
                
                <div className="absolute_div">
                <div className="grid_system">
                    <Row className="bottom_space">
                    <Col lg={2} xs={2}></Col>
                        <Col lg={3} xs={3}>
                            <div>
                                <p className="title_first">EDUCATION</p>
                            </div>
                        </Col>
                        <Col lg={1} xs={1}>
                            <div>
                                <a href="#screen_twelve">
                                    <img className="zoom-in-out-box" id="aglaCircle" src={aglaCircle}></img>
                                </a>
                            </div>
                        </Col>
                        <Col lg={6} xs={6}> 
                            <div>
                                <a href="#screen_twelve">
                                    <p id="title_last">AGLA CHAPTER: SIDE-HUSTLE</p>
                                </a>
                                <p className="title_last_below">BONUS section: TECH (UN)CHECK(ED)</p>
                            </div>
                        </Col>
                    </Row>
                    
                    <Row className="bottom_space">
                    <Col lg={2} xs={2}></Col>
                        <Col lg={3} xs={3}>
                            <div>
                                <p className="title_first">money</p>
                            </div>
                        </Col>
                        <Col lg={1} xs={1}>
                            <div>
								<a href="#screen_thirty_four">
                            		<img className="zoom-in-out-box" id="aglaCircle" src={ogbaeCircle}></img>
								</a>
                            </div>
                        </Col>
                        <Col lg={6} xs={6}>
                            <div>
                                <a href="#screen_thirty_four" >
                                <p id="title_last">OG BAE RETURNS</p>
                                </a>
                                
                                <p className="title_last_below">BONUS section: Share of the household’s wallet</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="bottom_space">
                    <Col lg={2} xs={2}></Col>
                        <Col lg={3} xs={3}>
                            <div>
                                <p className="title_first">romance</p>
                            </div>
                        </Col>
                        <Col lg={1} xs={1}>
                            <div>
                                <a href="#screen_fifty_five">                                    
                                <img className="zoom-in-out-box" id="aglaCircle" src={romanceCircle}></img>
                                </a>
                            </div>
                        </Col>
                        <Col lg={6} xs={6}>
                            <div>
                                <a href="#screen_fifty_five">
                                <p id="title_last">PYAR <span className="strike">DOSTI</span> DHOKHA HAI</p>
                                </a>
                            
                            <p className="title_last_below">BONUS section: Friend Trust Deficit</p>
                                </div>
                        </Col>
                    </Row>
                    <Row className="bottom_space">
                    <Col lg={2} xs={2}></Col>
                        <Col lg={3} xs={3}>
                            <div>
                                <p className="title_first">national interest</p>
                            </div>
                        </Col>
                        <Col lg={1} xs={1}>
                            <div>
                                <a href="#screen81">
                                <img className="zoom-in-out-box" id="aglaCircle" src={volumeCircle}></img>
                                </a>
                            </div>
                        </Col>
                        <Col lg={6} xs={6}>
                            <div>
                            <a href="#screen81">
                                <p id="title_last">VOLUME OVER WORDS</p>
                            </a>
                                <p className="title_last_below">BONUS section: Swades – We the people</p>
                                <p className="title_last_below">BONUS section: Not A Feminist</p>
                            </div>
                        </Col>
                    </Row>
                <Row className="bottom_space">
                <Col lg={2} xs={2}></Col>
                    <Col lg={3} xs={3}>
                        <div>
                            <p className="title_first">content</p>
                        </div>
                    </Col>
                    <Col lg={1} xs={1}>
                        <div>
                        <a href="#screen117">
                        <img className="zoom-in-out-box" id="aglaCircle" src={applyCircle}></img>
                        </a>
                        </div>
                    </Col>
                    <Col lg={6} xs={6}>
                        <div>
                        <a href="#screen117">
                        <p id="title_last">APPLY CONTENT TO BURNT AREAS</p>
                        </a>
                                <p className="title_last_below">BONUS section: Youth Icons</p>
                                
                                <p className="title_last_below">BONUS section: CHOTA PACKET, BADA WOKE</p>
                        </div>
                    </Col>
                </Row>
                <Row className="bottom_space">
                <Col lg={2} xs={2}></Col>
                    <Col lg={3} xs={3}>
                        <div>
                            <p className="title_first">Family, Friends & Spirituality</p>
                        </div>
                    </Col>
                    <Col lg={1} xs={1}>
                        <div>
                            <a href="#screen150">
                            <img className="zoom-in-out-box"  id="aglaCircle" src={alexaCircle}></img>
                            </a>
                       
                        </div>
                    </Col>
                    <Col lg={6} xs={6}>
                        <div>
                        <a href="#screen150">
                        <p  id="title_last">ALEXA, GIVE ME PEACE OF MIND</p>
                        </a>
                        
                        <p className="title_last_below">BONUS section: Health & Wellness hangs in the balance</p>
                        </div>
                    </Col>
                </Row>
                <Row className="bottom_space">
                <Col lg={2} xs={2}></Col>
                    <Col lg={3} xs={3}>
                        <div>
                            <p className="title_first">Covid19 Outlook</p>
                        </div>
                    </Col>
                    <Col lg={1} xs={1}>
                        <div>
                        <a href="#screen179">
                        <img className="zoom-in-out-box"  id="aglaCircle" src={covidCircle}></img>
                            </a>
                       
                        </div>
                    </Col>
                    <Col lg={6} xs={6}>
                        <div>
                            <a href="#screen179">
                                <p id="title_last">COVID IS TEMPORARY, ASPIRATIONS ARE PERMANENT</p>
                            </a>
                                <p className="title_last_below">BONUS section: THE WORK BEHIND THE WORKINGS</p>
                                <a href="#screen197">
                                <p id="title_last_small">WHO MADE IT HAPPEN?</p>
                                </a>
                                
                                <a href="#screen200">
                                <p id="title_last_small">DISCLAIMER</p>
                                </a>
                        </div>
                    </Col>
                </Row>
                </div>
                </div>
            </div>
        </div>
    )
}

export default IndexEleven
