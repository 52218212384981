import React from 'react'
import '../Styles/MainLoading.css'
import eye from '../../Assets/loader/SVG/eye.svg';
import { Player } from '@lottiefiles/react-lottie-player';


export default function() {
    return (
        <div  className="loading_style" >
            <div>
                <Player className="aR" style={{width:'50vw'}} autoplay loop src="https://assets2.lottiefiles.com/packages/lf20_roo2nddd.json"></Player>
            </div>
        </div>
    )
}