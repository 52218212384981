import React from 'react'
import '../Styles/ScreenFive.css'
import page5 from '../../Assets/background/page5-01.svg';


function ScreenFive() {
    return (
        <div  className="screen_five" ><div className="pagination_div"><p className="pagination_style">5</p></div>
            <img className="page5_style" src={page5}></img>
        </div>
    )
}

export default ScreenFive
