import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import page24 from '../../Assets/json/page24.json';

import '../Styles/ScreenTwenty.css'

function ScreenTwenty() {
    return (
        <div  className="screen_twenty"><div className="pagination_div"><p className="pagination_style">24</p></div>
            <Player style={{ width:'90vw'}} autoplay loop src={page24}></Player>  
        </div>
    )
}

export default ScreenTwenty