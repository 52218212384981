import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import page05BG from '../../Assets/page05BG-01.svg';
import AOS from 'aos';
import '../Styles/ScreenTwelve.css'

function ScreenTwelve() {
    return (
        <div id="screen_twelve" className="screen_twelve" ><div className="pagination_div"><p className="pagination_style">13</p></div>
            <Player style={{ width:'90vw'}} autoplay loop src="https://assets4.lottiefiles.com/packages/lf20_2ry2hwhj.json"></Player>
        </div>
    )
}

export default ScreenTwelve
