import React, {useEffect, useState} from 'react';
import page05BG from '../../Assets/mobile/newpage11-01.svg';
import cancel from '../../Assets/cancel.svg';
import '../Styles/ScreenEleven.css'
import Modal from 'react-bootstrap/Modal';
import ScreenTwelve from './ScreenTwelve';
import ScreenThirteen from './ScreenThirteen';


function ScreenEleven() {
    const [screenName,setScreenName] = useState('')
    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
  
    function handleShow(breakpoint) {
      setFullscreen(breakpoint);
      setShow(true);
    }

    useEffect(()=>{


        let a = document.getElementById('alphasvg')
        var css = 'Agla:hover{ transform: scale(1.2) ; }';
            var style = document.createElement('style');

            if (style.styleSheet) {
                style.styleSheet.cssText = css;
            } else {
                style.appendChild(document.createTextNode(css));
            }
            
            let nw = a.contentDocument.getElementById('Agla');
                console.log(nw);

        a.addEventListener("load",function(e){

            var svgDoc = a.contentDocument;
            var ogbae = svgDoc.getElementById("ogbae");
            svgDoc.getElementById("ogbae").style.cursor = 'pointer';

            var agla = svgDoc.getElementById("agla");
            svgDoc.getElementById("agla").style.cursor = 'pointer';

            ogbae.addEventListener("click",function(){
            setScreenName(<ScreenThirteen/>)
            setShow(true)
            }, 
            false);

            agla.addEventListener("click",function(){
                    setScreenName(<ScreenTwelve/>)
                    setShow(true)
            
            },
            false);
        }, false);
    },[])
    
    return (

        <div  className="screen_eleven"><div className="pagination_div"><p className="pagination_style">1</p></div>
            <div className="">
                
                <object style={{height:'100vh', width:'100vw'}} data={page05BG} type="image/svg+xml"
                    id="alphasvg" width="100vw" height="100vh">
                </object>
            </div>
            <Modal show={show} fullscreen={fullscreen} >
                <div onClick={() => setShow(false)} className="cancel_button_div">
                    <img className="cancel_button" src={cancel}></img>
                </div>
                <Modal.Body>
                    {screenName}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ScreenEleven