import React from 'react';
import '../Styles/RotateScreen.css';

export default props => {
	React.useEffect(() => {
		console.log(`animation mounted1`);
	}, [])
	return (<>

	<div className="divMView">
		<div>
			<div className="phone">
			</div>
		   <p className="styleTxtP">Please rotate your device</p>
		</div>
	</div>
	</>);
};