import React from 'react'
import '../Styles/ScreenSeventeen.css'
import vid from '../../Assets/mp4/page17.mp4';
import loaderIcon from '../../Assets/loader/loaderBg-02.svg';

function ScreenSeventeen() {
    const vidRef = React.useRef(null)
		const playVid = () => {
			vidRef.current.play();
		}
    return (
        <div className="screen_seventeen" ><div className="pagination_div"><p className="pagination_style">19</p></div>
            <video poster={loaderIcon} autoPlay={true} muted autoStart={true} ref={vidRef} onEnded={(vidd) => {vidd.target.currentTime=4; playVid()}} allow="autoplay" style={{width:'90vw'}}>
                <source src={vid} autoPlay={true} autoStart={true} type="video/mp4"/>
            </video>
        </div>
    )
}

export default ScreenSeventeen