import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import '../Styles/ScreenOne.css';

function ScreenOne() {
    return (
        <div>
            <div className="screen_one">
            <div className="pagination_div">
				<p className="pagination_style">1</p>
			</div>
                <Player className="aR" style={{width:'90vw'}} autoplay loop src="https://assets9.lottiefiles.com/packages/lf20_jvndfjut.json"></Player>
            </div>
        </div>
    )
}

export default ScreenOne
