import React from 'react'
import '../Styles/ScreenEight.css'
import page8 from '../../Assets/background/pagenew8-01.svg';
function ScreenEight(props) {
    return (
        <div  className="screen_eight" ><div className="pagination_div"><p className="pagination_style">9</p></div>
             <img className="page8_style" src={page8}></img> 
        </div>
    )
}

export default ScreenEight
