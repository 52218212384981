import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import page35bet from '../../Assets/json/page35bet.json';
import '../Styles/ScreenThirtyFour.css'

function ScreenThirtyFour() {
    const vidRef = React.useRef(null)
		const playVid = () => {
			vidRef.current.play();
		}
    return (
        <div className="screen_thirty_four screen_thirty_five"><div className="pagination_div"><p className="pagination_style">35</p></div>
            <Player className="aR"  style={{width:'90vw'}} autoplay loop src={page35bet}></Player>
        </div>
    )
}

export default ScreenThirtyFour
