import React from 'react'
import '../Styles/ScreenTwo.css'
import { Player } from '@lottiefiles/react-lottie-player';
import page2 from '../../Assets/json/page2.json';

function ScreenTwo() {
    return (
        <div>
        
        <div  className="screen_two">
            <div className="pagination_div">
                <p className="pagination_style">2</p>
            </div>
            <Player  className="aR" style={{width:'90vw', zIndex:16}} autoplay loop src={page2}></Player>
        </div>
        </div>
    )
}

export default ScreenTwo