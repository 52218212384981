import React from 'react'
import '../Styles/ScreenNine.css'
import page9 from '../../Assets/json/page9.json';
import { Player } from '@lottiefiles/react-lottie-player';

function ScreenNine() {
   
    return (
        <div className="screen_nine" ><div className="pagination_div"><p className="pagination_style">10</p></div>
            <Player className="aR"  style={{width:'90vw'}} autoplay loop src={page9}></Player>
        </div>
    )
}

export default ScreenNine