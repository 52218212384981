import React from 'react'
import '../Styles/ScreenFifteen.css'
import page16 from '../../Assets/background/page16-01.svg';


function ScreenFifteen() {

    return (
        <div className="screen_fifteen" ><div className="pagination_div"><p className="pagination_style">16</p></div>
           <img className="page3_style" src={page16}></img> 
        </div>
    )
}

export default ScreenFifteen