import React from 'react'
import '../Styles/ScreenTen.css'
import { Player } from '@lottiefiles/react-lottie-player';

function ScreenTen() {
    const vidRef = React.useRef(null)
		const playVid = () => {
			vidRef.current.play();
		}
    return (
        <div className="screen_ten"><div className="pagination_div"><p className="pagination_style">11</p></div>
       <Player className="aR" style={{width:'90vw'}} autoplay loop src="https://assets9.lottiefiles.com/packages/lf20_jvndfjut.json"></Player>
        </div>
    )
}

export default ScreenTen
