import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import page37 from '../../Assets/background/page37-01.svg';
import '../Styles/ScreenThirtySeven.css'

function ScreenThirtySeven() {
    return (
        <div className="screen_thirty_seven"><div className="pagination_div"><p className="pagination_style">37</p></div>
             <img src={page37}></img>
        </div>
    )
}

export default ScreenThirtySeven
