import React,{Suspense, useState,useEffect} from 'react'
import './Container.css'
import AOS from 'aos';
import loaderIcon from '../Assets/loader/loader.svg';
import BackToTop from '../Assets/up-arrow.svg';
import RotateScreen from './Screens/RotateScreen';
import 'aos/dist/aos.css';
import MainContainer from './Screens/MainLoading.js';
const ScreenTwo  =  React.lazy( () => import('./Screens/ScreenTwo'));
const ScreenThree  =  React.lazy( () => import('./Screens/ScreenThree'));
const ScreenFour  =  React.lazy( () => import('./Screens/ScreenFour'));
const ScreenFive  =  React.lazy( () => import('./Screens/SreenFive'));
const ScreenSix  =  React.lazy( () => import('./Screens/ScreenSix'));
const ScreenSeven  =  React.lazy( () => import('./Screens/ScreenSeven'));
const ScreenEight  =  React.lazy( () => import('./Screens/ScreenEight'));
const ScreenNine  =  React.lazy( () => import('./Screens/ScreenNine'));
const ScreenTen  =  React.lazy( () => import('./Screens/ScreenTen'));
const ScreenTwelve  =  React.lazy( () => import('./Screens/ScreenTwelve'));
const ScreenThirteen  =  React.lazy( () => import('./Screens/ScreenThirteen'));
const ScreenFourteen  =  React.lazy( () => import('./Screens/ScreenFourteen'));
const ScreenFifteen  =  React.lazy( () => import('./Screens/ScreenFifteen'));
const ScreenSixteen  =  React.lazy( () => import('./Screens/ScreenSixteen'));
const ScreenSeventeen  =  React.lazy( () => import('./Screens/ScreenSeventeen'));
const ScreenEighteen  =  React.lazy( () => import('./Screens/ScreenEighteen'));
const ScreenNineteen  =  React.lazy( () => import('./Screens/ScreenNineteen'));
const ScreenTwenty  =  React.lazy( () => import('./Screens/ScreenTwenty'));
const ScreenTwentyOne  =  React.lazy( () => import('./Screens/ScreenTwentyOne'));
const ScreenTwentyTwo  =  React.lazy( () => import('./Screens/ScreenTwentyTwo'));
const ScreenTwentyThree  =  React.lazy( () => import('./Screens/ScreenTwentyThree'));
const ScreenThirty  =  React.lazy( () => import('./Screens/ScreenThirty'));
const ScreenThirtyFour  =  React.lazy( () => import('./Screens/ScreenThirtyFour'));
const ScreenThirtyFive  =  React.lazy( () => import('./Screens/ScreenThirtyFive'));
const ScreenThirtySix  =  React.lazy( () => import('./Screens/ScreenThirtySix'));
const ScreenThirtySeven  =  React.lazy( () => import('./Screens/ScreenThirtySeven'));
const ScreenThirtyNine  =  React.lazy( () => import('./Screens/ScreenThirtyNine'));
const IndexEleven  =  React.lazy( () => import('./Screens/IndexEleven'));
const ScreenFourtyOne  =  React.lazy( () => import('./Screens/ScreenFourtyOne'));
const ScreenFourtyThree = React.lazy(() => import ('./Screens/ScreenFourtyThree'));
const ScreenFourtyFour = React.lazy(() => import ('./Screens/ScreenFourtyFour'));
// const RotateScreen  =  React.lazy( () => import('./Screens/RotateScreen'));
const ScreenOne = React.lazy(() => import ('./Screens/ScreenOne'));
const ScreenFiftyNine  =  React.lazy( () => import('./Screens/ScreenFiftyNine'));
const ScreenSixtyTwo  =  React.lazy( () => import('./Screens/ScreenSixtyTwo'));
const ScreenSixtyFour  =  React.lazy( () => import('./Screens/ScreenSixtyFour'));
const ScreenSixtyFive  =  React.lazy( () => import('./Screens/ScreenSixtyFive'));
const ScreenSixtySix  =  React.lazy( () => import('./Screens/ScreenSixtySix'));
const ScreenSixtyEight  =  React.lazy( () => import('./Screens/ScreenSixtyEight'));
const ScreenSeventy  =  React.lazy( () => import('./Screens/ScreenSeventy'));
const ScreenSeventyTwo  =  React.lazy( () => import('./Screens/ScreenSeventyTwo'));
const ScreenSeventyFour  =  React.lazy( () => import('./Screens/ScreenSeventyFour'));
const ScreenEightyOne  =  React.lazy( () => import('./Screens/ScreenEightyOne'));
const ScreenEightyTwo  =  React.lazy( () => import('./Screens/ScreenEightyTwo'));
const ScreenEightyThree  =  React.lazy( () => import('./Screens/ScreenEightyThree'));
const ScreenEightyEight  =  React.lazy( () => import('./Screens/ScreenEightyEight'));
const ScreenEightyNine  =  React.lazy( () => import('./Screens/ScreenEightyNine'));
const ScreenNinetyOne  =  React.lazy( () => import('./Screens/ScreenNinetyOne'));
const ScreenNinetyThree  =  React.lazy( () => import('./Screens/ScreenNinetyThree'));
const ScreenNinetyFive  =  React.lazy( () => import('./Screens/ScreenNinetyFive'));
const Screen116  =  React.lazy( () => import('./Screens/Screen116'));
const Screen117  =  React.lazy( () => import('./Screens/Screen117'));
const Screen118  =  React.lazy( () => import('./Screens/Screen118'));
const Screen120  =  React.lazy( () => import('./Screens/Screen120'));
const Screen121  =  React.lazy( () => import('./Screens/Screen121'));
const Screen123  =  React.lazy( () => import('./Screens/Screen123'));
const Screen126  =  React.lazy( () => import('./Screens/Screen126'));
const Screen127  =  React.lazy( () => import('./Screens/Screen127'));
const Screen129  =  React.lazy( () => import('./Screens/Screen129'));
const Screen130  =  React.lazy( () => import('./Screens/Screen130'));
const Screen132  =  React.lazy( () => import('./Screens/Screen132'));
const Screen140  =  React.lazy( () => import('./Screens/Screen140'));
const Screen149  =  React.lazy( () => import('./Screens/Screen149'));
const Screen150  =  React.lazy( () => import('./Screens/Screen150'));
const Screen158  =  React.lazy( () => import('./Screens/Screen158'));
const Screen162  =  React.lazy( () => import('./Screens/Screen162'));
const Screen165  =  React.lazy( () => import('./Screens/Screen165'));
const Screen168  =  React.lazy( () => import('./Screens/Screen168'));
const Screen179  =  React.lazy( () => import('./Screens/Screen179'));
const Screen180  =  React.lazy( () => import('./Screens/Screen180'));
const Screen186  =  React.lazy( () => import('./Screens/Screen186'));
const Screen187  =  React.lazy( () => import('./Screens/Screen187'));
const Screen190  =  React.lazy( () => import('./Screens/Screen190'));
const Screen193  =  React.lazy( () => import('./Screens/Screen193'));
const Screen194  =  React.lazy( () => import('./Screens/Screen194'));
const Screen195  =  React.lazy( () => import('./Screens/Screen195'));
const Screen196  =  React.lazy( () => import('./Screens/Screen196'));
const Screen197  =  React.lazy( () => import('./Screens/Screen197'));
const Screen198  =  React.lazy( () => import('./Screens/Screen198'));
const Screen199  =  React.lazy( () => import('./Screens/Screen199'));
const Screen200  =  React.lazy( () => import('./Screens/Screen200'));
const ScreenFiftySix  =  React.lazy( () => import('./Screens/ScreenFiftySix'));
const ScreenFiftySeven  =  React.lazy( () => import('./Screens/ScreenFiftySeven'));
const ScreenSixty  =  React.lazy( () => import('./Screens/ScreenSixty'));
const ScreenSixtyNine  =  React.lazy( () => import('./Screens/ScreenSixtyNine'));
const ScreenSeventyThree  =  React.lazy( () => import('./Screens/ScreenSeventyThree'));
const ScreenEightyFour  =  React.lazy( () => import('./Screens/ScreenEightyFour'));
const ScreenEightyFive  =  React.lazy( () => import('./Screens/ScreenEightyFive'));
const ScreenNinety  =  React.lazy( () => import('./Screens/ScreenNinety'));
const ScreenNinetyFour  =  React.lazy( () => import('./Screens/ScreenNinetyFour'));
const Screen105  =  React.lazy( () => import('./Screens/Screen105'));
const Screen122  =  React.lazy( () => import('./Screens/Screen122'));
const Screen124  =  React.lazy( () => import('./Screens/Screen124'));
const Screen131  =  React.lazy( () => import('./Screens/Screen131'));
const Screen151  =  React.lazy( () => import('./Screens/Screen151'));
const Screen152  =  React.lazy( () => import('./Screens/Screen152'));
const Screen153  =  React.lazy( () => import('./Screens/Screen153'));
const Screen154  =  React.lazy( () => import('./Screens/Screen154'));
const Screen155  =  React.lazy( () => import('./Screens/Screen155'));
const Screen157  =  React.lazy( () => import('./Screens/Screen157'));
const Screen160  =  React.lazy( () => import('./Screens/Screen160'));
const Screen164  =  React.lazy( () => import('./Screens/Screen164'));
const Screen167  =  React.lazy( () => import('./Screens/Screen167'));
const Screen181  =  React.lazy( () => import('./Screens/Screen181'));
const Screen182  =  React.lazy( () => import('./Screens/Screen182'));
const Screen183  =  React.lazy( () => import('./Screens/Screen183'));
const Screen184  =  React.lazy( () => import('./Screens/Screen184'));
const Screen188  =  React.lazy( () => import('./Screens/Screen188'));
const Screen189  =  React.lazy( () => import('./Screens/Screen189'));
const Screen192  =  React.lazy( () => import('./Screens/Screen192'));
const ScreenTwentyFour  =  React.lazy( () => import('./Screens/ScreenTwentyFour'));
const ScreenTwentySix  =  React.lazy( () => import('./Screens/ScreenTwentySix'));
const ScreenTwentyEight  =  React.lazy( () => import('./Screens/ScreenTwentyEight'));
const ScreenTwentySeven  =  React.lazy( () => import('./Screens/ScreenTwentySeven'));
const ScreenTwentyNine  =  React.lazy( () => import('./Screens/ScreenTwentyNine'));
const ScreenThirtyTwo  =  React.lazy( () => import('./Screens/ScreenThirtyTwo'));
const ScreenFourtyFive  =  React.lazy( () => import('./Screens/ScreenFourtyFive'));
const ScreenFourtySix  =  React.lazy( () => import('./Screens/ScreenFourtySix'));
const ScreenFourtyNine  =  React.lazy( () => import('./Screens/ScreenFourtyNine'));
const ScreenFifty  =  React.lazy( () => import('./Screens/ScreenFifty'));
const ScreenFiftyOne  =  React.lazy( () => import('./Screens/ScreenFiftyOne'));
const ScreenFiftyTwo  =  React.lazy( () => import('./Screens/ScreenFiftyTwo'));
const ScreenFiftyThree  =  React.lazy( () => import('./Screens/ScreenFiftyThree'));
const ScreenFiftyFour  =  React.lazy( () => import('./Screens/ScreenFiftyFour'));
const ScreenFiftyFive  =  React.lazy( () => import('./Screens/ScreenFiftyFive'));
const ScreenSixtyOne  =  React.lazy( () => import('./Screens/ScreenSixtyOne'));
const ScreenSeventyFive  =  React.lazy( () => import('./Screens/ScreenSeventyFive'));
const ScreenSeventySix  =  React.lazy( () => import('./Screens/ScreenSeventySix'));
const ScreenSeventySeven  =  React.lazy( () => import('./Screens/ScreenSeventySeven'));
const ScreenSeventyEight  =  React.lazy( () => import('./Screens/ScreenSeventyEight'));
const ScreenEighty  =  React.lazy( () => import('./Screens/ScreenEighty'));
const ScreenEightySix  =  React.lazy( () => import('./Screens/ScreenEightySix'));
const ScreenNinetySix  =  React.lazy( () => import('./Screens/ScreenNinetySix'));
const ScreenNinetySeven  =  React.lazy( () => import('./Screens/ScreenNinetySeven'));
const ScreenNinetyNine  =  React.lazy( () => import('./Screens/ScreenNinetyNine'));
const Screen100  =  React.lazy( () => import('./Screens/Screen100'));
const Screen101  =  React.lazy( () => import('./Screens/Screen101'));
const Screen102  =  React.lazy( () => import('./Screens/Screen102'));
const Screen103  =  React.lazy( () => import('./Screens/Screen103'));
const Screen104  =  React.lazy( () => import('./Screens/Screen104'));
const Screen106  =  React.lazy( () => import('./Screens/Screen106'));
const Screen107  =  React.lazy( () => import('./Screens/Screen107'));
const Screen108  =  React.lazy( () => import('./Screens/Screen108'));
const Screen109  =  React.lazy( () => import('./Screens/Screen109'));
const Screen110  =  React.lazy( () => import('./Screens/Screen110'));
const Screen111  =  React.lazy( () => import('./Screens/Screen111'));
const Screen112  =  React.lazy( () => import('./Screens/Screen112'));
const Screen113  =  React.lazy( () => import('./Screens/Screen113'));
const Screen115  =  React.lazy( () => import('./Screens/Screen115'));
const Screen119  =  React.lazy( () => import('./Screens/Screen119'));
const Screen133  =  React.lazy( () => import('./Screens/Screen133'));
const Screen134  =  React.lazy( () => import('./Screens/Screen134'));
const Screen135  =  React.lazy( () => import('./Screens/Screen135'));
const Screen137  =  React.lazy( () => import('./Screens/Screen137'));
const Screen138  =  React.lazy( () => import('./Screens/Screen138'));
const Screen139  =  React.lazy( () => import('./Screens/Screen139'));
const Screen141  =  React.lazy( () => import('./Screens/Screen141'));
const Screen142  =  React.lazy( () => import('./Screens/Screen142'));
const Screen143  =  React.lazy( () => import('./Screens/Screen143'));
const Screen144  =  React.lazy( () => import('./Screens/Screen144'));
const Screen145  =  React.lazy( () => import('./Screens/Screen145'));
const Screen146  =  React.lazy( () => import('./Screens/Screen146'));
const Screen147  =  React.lazy( () => import('./Screens/Screen147'));
const Screen148  =  React.lazy( () => import('./Screens/Screen148'));
const Screen161  =  React.lazy( () => import('./Screens/Screen161'));
const Screen169  =  React.lazy( () => import('./Screens/Screen169'));
const Screen170  =  React.lazy( () => import('./Screens/Screen170'));
const Screen171  =  React.lazy( () => import('./Screens/Screen171'));
const Screen172  =  React.lazy( () => import('./Screens/Screen172'));
const Screen173  =  React.lazy( () => import('./Screens/Screen173'));
const Screen174  =  React.lazy( () => import('./Screens/Screen174'));
const Screen175  =  React.lazy( () => import('./Screens/Screen175'));
const Screen176  =  React.lazy( () => import('./Screens/Screen176'));
const Screen177  =  React.lazy( () => import('./Screens/Screen177'));
const Screen178  =  React.lazy( () => import('./Screens/Screen178'));
const Screen185  =  React.lazy( () => import('./Screens/Screen185'));
const Screen191  =  React.lazy( () => import('./Screens/Screen191'));
const Screen114  =  React.lazy( () => import('./Screens/Screen114'));
const Screen125  =  React.lazy( () => import('./Screens/Screen125'));
const Screen128  =  React.lazy( () => import('./Screens/Screen128'));
const Screen136  =  React.lazy( () => import('./Screens/Screen136'));
const Screen156  =  React.lazy( () => import('./Screens/Screen156'));
const Screen159  =  React.lazy( () => import('./Screens/Screen159'));
const Screen163  =  React.lazy( () => import('./Screens/Screen163'));
const Screen166  =  React.lazy( () => import('./Screens/Screen166'));
const ScreenTwentyFive  =  React.lazy( () => import('./Screens/ScreenTwentyFive'));
const ScreenThirtyOne  =  React.lazy( () => import('./Screens/ScreenThirtyOne'));
const ScreenThirtyThree  =  React.lazy( () => import('./Screens/ScreenThirtyThree'));
const ScreenThirtyEight  =  React.lazy( () => import('./Screens/ScreenThirtyEight'));
const ScreenFourty  =  React.lazy( () => import('./Screens/ScreenFourty'));
const ScreenFourtyEight  =  React.lazy( () => import('./Screens/ScreenFourtyEight'));
const ScreenFourtySeven  =  React.lazy( () => import('./Screens/ScreenFourtySeven'));
const ScreenFiftyEight  =  React.lazy( () => import('./Screens/ScreenFiftyEight'));
const ScreenSixtyThree  =  React.lazy( () => import('./Screens/ScreenSixtyThree'));
const ScreenSixtySeven  =  React.lazy( () => import('./Screens/ScreenSixtySeven'));
const ScreenSeventyOne  =  React.lazy( () => import('./Screens/ScreenSeventyOne'));
const ScreenSeventyNine  =  React.lazy( () => import('./Screens/ScreenSeventyNine'));
const ScreenEightySeven  =  React.lazy( () => import('./Screens/ScreenEightySeven'));
const ScreenNinetyTwo  =  React.lazy( () => import('./Screens/ScreenNinetyTwo'));
const ScreenNinetyEight  =  React.lazy( () => import('./Screens/ScreenNinetyEight'));
const ScreenBet6  =  React.lazy( () => import('./Screens/ScreenBet6'));
const Screen18Bet  =  React.lazy( () => import('./Screens/Screen18Bet'));
const Screen21Bet  =  React.lazy( () => import('./Screens/Screen21Bet'));
const Screen23Bet  =  React.lazy( () => import('./Screens/Screen23Bet'));
const ScreenFourtyTwo  =  React.lazy( () => import('./Screens/ScreenFourtyTwo'));
const Screen28Bet  =  React.lazy( () => import('./Screens/Screen28bet'));
const Screen29Bet  =  React.lazy( () => import('./Screens/Screen29bet'));

const setTwo = () => React.lazy(() => <></>)

	const LoadingComponent = () => {
		return (
		<div className="loader_style">

		</div>
			)
	}


function Container() {

    const [screenName,setScreenName] = useState(<ScreenOne/>)
    const [screenNameCount,setScreenNameCount] = useState(0)
    const [mobileView, setMobileView] = useState(false);
	const [lastScrolled, setLastScrolled] = useState({});
	const [scrollerScreen, setScrollerScreen] = useState({});
	const [showMainLoader, setShowMainLoader] = useState(true);
	
	React.useEffect(() => {
        AOS.init({
        });
		window.onresize = function () {

			if(window.innerWidth < window.innerHeight)
				setMobileView(true);
			else
				setMobileView(false);
		}
		window.setTimeout(() => setShowMainLoader(false), 30000);
		if(window.innerWidth < window.innerHeight)
			setMobileView(true);
		return () => {
			console.log(`unmounting`);
		}
	}, [])

    return (mobileView?<RotateScreen/>:
        <div className="big_div">
			
            <div>
                <div class="outer-menu">
                <input class="checkbox-toggle" id="uncheck" type="checkbox"/>
                <div class="hamburger">
                    <div></div>
                </div>
                <div class="menu">
                    <div>
						<div>
							<ul>
								<li><a onClick={()=> document.getElementById('uncheck').checked=false} className="menu_title" href="#screen_twelve">AGLA CHAPTER: SIDE-HUSTLE</a></li>
								<li><a onClick={()=> document.getElementById('uncheck').checked=false} className="menu_title" href="#screen_thirty_four">OG BAE RETURNS</a></li>
								<li><a onClick={()=> document.getElementById('uncheck').checked=false} className="menu_title" className="menu_title" href="#screen_fifty_five">PYAR DOSTI DHOKHA HAI</a></li>
								<li><a onClick={()=> document.getElementById('uncheck').checked=false} className="menu_title" className="menu_title" href="#screen81">VOLUME OVER WORDS</a></li>  
								<li><a onClick={()=> document.getElementById('uncheck').checked=false} className="menu_title" className="menu_title" href="#screen117">APPLY CONTENT TO BURNT AREAS</a></li>
								<li><a onClick={()=> document.getElementById('uncheck').checked=false} className="menu_title" className="menu_title" href="#screen150">ALEXA, GIVE ME PEACE OF MIND</a></li>
								<li><a onClick={()=> document.getElementById('uncheck').checked=false} className="menu_title" className="menu_title" href="#screen179">COVID IS TEMPORARY, ASPIRATIONS ARE PERMANENT</a></li>
								<li><a onClick={()=> document.getElementById('uncheck').checked=false} className="menu_title" className="menu_title" href="#screen197">Who made it happen</a></li>
								<li><a onClick={()=> document.getElementById('uncheck').checked=false} className="menu_title" className="menu_title" href="#screen200">Disclaimer</a></li>
							</ul>
						</div>
                    </div>
                </div>
                </div>
            </div>
             <div className="main_section">
                <div className="star star1"></div>
                <div className="star star2"></div>
                <div className="star star3"></div>
                <div className="star star4"></div>
                <div className="star star5"></div>
                <div className="star star6"></div>
                <div className="star star7"></div>
                <div className="star star8"></div>
            </div>
			{showMainLoader && <MainContainer />}
			<div className="Container">
				<Suspense fallback={<LoadingComponent />}>
					<ScreenOne/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenTwo/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenThree/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFour/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFive/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenBet6/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSix/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSeven/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenEight/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenNine/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenTen/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<IndexEleven/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenTwelve />
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenThirteen/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFourteen/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFifteen/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSixteen/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen18Bet/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSeventeen/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenEighteen/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen21Bet/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenNineteen/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen23Bet/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenTwenty/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenTwentyFive/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenTwentyOne/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenThirtyOne/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen28Bet/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen29Bet/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenThirty/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenTwentyEight/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenTwentyNine/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenThirtyTwo/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenThirtyFour/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenThirtyFive/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenThirtySix/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenThirtySeven/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenThirtyEight/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenThirtyNine/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFourty/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFourtyOne/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFourtyTwo/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFourtyThree/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFourtyFour/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFourtyFive/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFourtySix/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFourtySeven/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFourtyEight/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFourtyNine/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFifty/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFiftyOne/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFiftyTwo/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFiftyThree/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFiftyFour/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFiftyFive/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFiftySix/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFiftySeven/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFiftyEight/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenFiftyNine/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSixty/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSixtyOne/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSixtyTwo/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSixtyThree/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSixtyFour/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSixtyFive/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSixtySix/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSixtySeven/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSixtyEight/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSixtyNine/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSeventy/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSeventyOne/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSeventyTwo/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSeventyThree/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSeventyFour/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSeventyFive/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSeventySix/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSeventySeven/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSeventyEight/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenSeventyNine/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenEighty/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenEightyOne/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenEightyTwo/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenEightyThree/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenEightyFour/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenEightyFive/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenEightySix/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenEightySeven/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenEightyEight/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenEightyNine/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenNinety/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenNinetyOne/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenNinetyTwo/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenNinetyThree/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenNinetyFour/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenNinetyFive/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenNinetySix/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenNinetySeven/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenNinetyEight/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<ScreenNinetyNine/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen100/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen101/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen102/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen103/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen104/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen105/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen106/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen107/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen108/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen109/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen110/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen111/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen112/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen113/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen114/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen115/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen116/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen117/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen118/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen119/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen120/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen121/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen122/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen123/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen124/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen125/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen126/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen127/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen128/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen129/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen130/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen131/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen132/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen133/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen134/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen135/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen136/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen137/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen138/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen139/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen140/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen141/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen142/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen143/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen144/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen145/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen146/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen147/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen148/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen149/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen150/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen151/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen152/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen153/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen154/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen155/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen156/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen157/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen158/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen159/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen160/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen161/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen162/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen163/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen164/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen165/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen166/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen167/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen168/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen169/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen170/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen171/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen172/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen173/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen174/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen175/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen176/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen177/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen178/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen179/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen180/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen181/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen182/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen183/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen184/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen185/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen186/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen187/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen188/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen189/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen190/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen191/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen192/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen193/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen194/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen195/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen196/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen197/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen198/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen199/>
				</Suspense>
				<Suspense fallback={<LoadingComponent />}>
					<Screen200/>
				</Suspense>

				{screenNameCount == 0?'':
				<div className="container_floating">
					<p className="text_floating">{screenNameCount}</p>
				</div>
				} 
				<div onClick={()=>{window.scrollBy(0,500)}} className="mouse_scroll">
					<div className="mouse">
						<div className="wheel"></div>
					</div>
					<div>
						<span className="m_scroll_arrows unu"></span>
						<span className="m_scroll_arrows doi"></span>
						<span className="m_scroll_arrows trei"></span>
					</div>
				</div>
				<div className="back_to_top" onClick={()=>{window.scrollTo(0,0)}}>
					<img src={BackToTop}></img>
				</div>
			</div>
		</div>
    )
}

export default Container
