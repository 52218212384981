import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import page36bet from '../../Assets/json/page36bet.json';
import '../Styles/ScreenThirtySix.css'

function ScreenThirtySix() {
    const vidRef = React.useRef(null)
    const playVid = () => {
        vidRef.current.play();
    }
    return (
        <div className="screen_thirty_six"><div className="pagination_div"><p className="pagination_style">36</p></div>
              <Player className="aR"  style={{width:'90vw'}} autoplay loop src={page36bet}></Player>
        </div>
    )
}

export default ScreenThirtySix
