import React from 'react'
import '../Styles/ScreenTwentyTwo.css'
import page21 from '../../Assets/background/page22-01.svg';
function ScreenTwentyTwo() {
    return (
        <div className="screen_twenty_two" ><div className="pagination_div"><p className="pagination_style">27</p></div>
            <img className="page3_style" src={page21}></img> 
        </div>
    )
}

export default ScreenTwentyTwo