import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import '../Styles/ScreenEighteen.css'

function ScreenEighteen() {
    return (
        <div  className="screen_eighteen" ><div className="pagination_div"><p className="pagination_style">20</p></div>
                     <Player style={{ width:'90vw'}} autoplay loop src="https://assets7.lottiefiles.com/packages/lf20_y2crzxzu.json"></Player>
        </div>
    )
}

export default ScreenEighteen