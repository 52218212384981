import React from 'react'
import page17 from '../../Assets/json/page17.json';
import '../Styles/ScreenSixteen.css'
import { Player } from '@lottiefiles/react-lottie-player';

function ScreenSixteen() {
    const vidRef = React.useRef(null)
		const playVid = () => {
			vidRef.current.play();
		}
    return (
        <div className="screen_sixteen" ><div className="pagination_div"><p className="pagination_style">17</p></div>
            <Player className="aR" style={{width:'90vw'}} autoplay loop src={page17}></Player>
        </div>
    )
}

export default ScreenSixteen