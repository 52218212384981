import React,{useState,useEffect} from 'react'
import './Container.css'
import ScreenOne from './Screens/ScreenOne';
import ScreenTwo from './Screens/ScreenTwo';
import ScreenThree from './Screens/ScreenThree';
import ScreenFour from './Screens/ScreenFour';
import ScreenFive from './Screens/SreenFive';
import ScreenSix from './Screens/ScreenSix';
import ScreenSeven from './Screens/ScreenSeven';
import ScreenEight from './Screens/ScreenEight';
import ScreenNine from './Screens/ScreenNine';
import ScreenTen from './Screens/ScreenTen';
import ScreenEleven from './Screens/ScreenEleven';
import ScreenTwelve from './Screens/ScreenTwelve';
import ScreenThirteen from './Screens/ScreenThirteen';
import ScreenFourteen from './Screens/ScreenFourteen';
import ScreenFifteen from './Screens/ScreenFifteen';
import ScreenSixteen from './Screens/ScreenSixteen';
import ScreenSeventeen from './Screens/ScreenSeventeen';
import ScreenEighteen from './Screens/ScreenEighteen';
import ScreenNineteen from './Screens/ScreenNineteen';
import ScreenTwenty from './Screens/ScreenTwenty';
import ScreenTwentyOne from './Screens/ScreenTwentyOne';
import ScreenTwentyTwo from './Screens/ScreenTwentyTwo';
import ScreenThirty from './Screens/ScreenThirty';
import ScreenThirtyFour from './Screens/ScreenThirtyFour';
import ScreenThirtyFive from './Screens/ScreenThirtyFive';
import ScreenThirtySix from './Screens/ScreenThirtySix';
import ScreenThirtySeven from './Screens/ScreenThirtySeven';
import ScreenThirtyNine from './Screens/ScreenThirtyNine';
import IndexEleven from './Screens/IndexEleven';
import AOS from 'aos';
import 'aos/dist/aos.css';
import RotateScreen from './Screens/RotateScreen';

function Container() {

    const [scrollHeight,setScrollHeight] = useState('')
    const [screenName,setScreenName] = useState(<ScreenOne/>)
    const [screenNameCount,setScreenNameCount] = useState(0)
    const [mobileView, setMobileView] = useState(false);
	React.useEffect(() => {
        AOS.init({
            // duration : 1000
        });
        window.addEventListener('scroll',findScrollHeight)
		window.onresize = function () {

			if(window.innerWidth < window.innerHeight)
				setMobileView(true);
			else
				setMobileView(false);
		}
		if(window.innerWidth < window.innerHeight)
			setMobileView(true);
		return () => {
			console.log(`unmounting`);
		}
	}, [])

    useEffect(() => {
        handleScreens()
    }, [scrollHeight])

    const handleScreens = () => {
        if(scrollHeight<800) {
            setScreenName(<ScreenOne/>)
            setScreenNameCount(0)
        }
        if(scrollHeight>800 && scrollHeight<1600) {
            setScreenName(<ScreenTwo/>)
            setScreenNameCount(1)
        }
        if(scrollHeight>1600 && scrollHeight<2400) {
            setScreenName(<ScreenThree/>)
            setScreenNameCount(2)
        }
        if(scrollHeight>2400 && scrollHeight<3200) {
            setScreenName(<ScreenFour/>)
            setScreenNameCount(3)
        }
        if(scrollHeight>3200 && scrollHeight<4000) {
            setScreenName(<ScreenFive/>)
            setScreenNameCount(4)
        }
        if(scrollHeight>4000 && scrollHeight<4800) {
            setScreenName(<ScreenSix/>)
            setScreenNameCount(5)
        }
        if(scrollHeight>4800 && scrollHeight<5600) {
            setScreenName(<ScreenSeven/>)
            setScreenNameCount(6)
        }
        if(scrollHeight>5600 && scrollHeight<6400) {
            setScreenName(<ScreenEight/>)
            setScreenNameCount(7)
        }
        if(scrollHeight>6400 && scrollHeight<7200) {
            setScreenName(<ScreenNine/>)
            setScreenNameCount(8)
        }
        if(scrollHeight>7200 && scrollHeight<8000) {
            setScreenName(<ScreenTen/>)
            setScreenNameCount(9)
        }
        if(scrollHeight>8800 && scrollHeight<9600) {
            setScreenName(<IndexEleven/>)
            setScreenNameCount(10)
        }
        if(scrollHeight>9600 && scrollHeight<10400) {
            setScreenName(<ScreenTwelve/>)
            setScreenNameCount(11)
        }
        if(scrollHeight>10400 && scrollHeight<11200) {
            setScreenName(<ScreenThirteen/>)
            setScreenNameCount(12)
        }
        if(scrollHeight>12000 && scrollHeight<12800) {
            setScreenName(<ScreenFourteen/>)
            setScreenNameCount(13)
        }
        if(scrollHeight>12800 && scrollHeight<13600) {
            setScreenName(<ScreenFifteen/>)
            setScreenNameCount(14)
        }
        if(scrollHeight>13600 && scrollHeight<14400){
            setScreenName(<ScreenSixteen/>)
            setScreenNameCount(15)
        }
        if(scrollHeight>14400 && scrollHeight<15200){
            setScreenName(<ScreenSeventeen/>)
            setScreenNameCount(16)
        }
        if(scrollHeight>16000 && scrollHeight<16800){
            setScreenName(<ScreenEighteen/>)
            setScreenNameCount(17)
        }
        if(scrollHeight>16800 && scrollHeight<17600){
            setScreenName(<ScreenNineteen/>)
            setScreenNameCount(18)
        }
        if(scrollHeight>17600 && scrollHeight<18400){
            setScreenName(<ScreenTwenty/>)
            setScreenNameCount(19)
        }
        if(scrollHeight>18400 && scrollHeight<19200){
            setScreenName(<ScreenTwentyOne/>)
            setScreenNameCount(20)
        }
        if(scrollHeight>19200 && scrollHeight<20000){
            setScreenName(<ScreenTwentyTwo/>)
            setScreenNameCount(21)
        }
        if(scrollHeight>20000 && scrollHeight<20800){
            setScreenName(<ScreenThirty/>)
            setScreenNameCount(22)
        }
        if(scrollHeight>20800 && scrollHeight<21600){
            setScreenName(<ScreenThirtyFour/>)
            setScreenNameCount(23)
        }
        if(scrollHeight>21600 && scrollHeight<23200){
            setScreenName(<ScreenThirtyFive/>)
            setScreenNameCount(23)
        }
        if(scrollHeight>23200 && scrollHeight<24000){
            setScreenName(<ScreenThirtySix/>)
            setScreenNameCount(23)
        }
        if(scrollHeight>24000 && scrollHeight<24800){
            setScreenName(<ScreenThirtySeven/>)
            setScreenNameCount(23)
        }
        if(scrollHeight>24800 && scrollHeight<25600){
            setScreenName(<ScreenThirtyNine/>)
            setScreenNameCount(23)
        }
    }
    const findScrollHeight = () => {
        setScrollHeight(window.scrollY)
    }
    return (mobileView?<RotateScreen/>:
        <div>
            <div className="Container">
            {screenName}
            {screenNameCount == 0?'':
            <div className="container_floating">
                <p className="text_floating">{screenNameCount}</p>
            </div>
            }
            <div className="mouse_scroll">

                <div className="mouse">
                    <div className="wheel"></div>
                </div>
                <div>
                    <span className="m_scroll_arrows unu"></span>
                    <span className="m_scroll_arrows doi"></span>
                    <span className="m_scroll_arrows trei"></span>
                </div>
                </div>
        </div>
        </div>
        
    )
}

export default Container
