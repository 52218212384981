import React from 'react'
import '../Styles/ScreenTwentyOne.css'
import { Player } from '@lottiefiles/react-lottie-player';
function ScreenTwentyOne() {
    return (
        <div  className="screen_twenty_one" ><div className="pagination_div"><p className="pagination_style">26</p></div>
         <Player className="aR" style={{width:'90vw'}} autoplay loop src="https://assets10.lottiefiles.com/packages/lf20_ui6ccfpv.json"></Player>
        </div>
    )
}

export default ScreenTwentyOne