import React from 'react'
import '../Styles/ScreenNineteen.css'
import vide from '../../Assets/mp4/page18.mp4';
import loaderIcon from '../../Assets/loader/loaderBg-02.svg';

function ScreenNineteen() {
    const vidRef = React.useRef(null)
		const playVid = () => {
			vidRef.current.play();
		}
    return (
        <div className="screen_nineteen" ><div className="pagination_div"><p className="pagination_style">22</p></div>
            <video poster={loaderIcon} autoPlay={true} muted autoStart={true} ref={vidRef} onEnded={(vidd) => {vidd.target.currentTime=4; playVid()}} allow="autoplay" style={{width:'90vw'}}>
                <source src={vide} autoPlay={true} autoStart={true} type="video/mp4"/>
            </video>
        </div>
    )
}

export default ScreenNineteen