import React from 'react'
import '../Styles/ScreenThirty.css'
import page30 from '../../Assets/background/page30-01.svg';
function ScreenThirty(props) {
    return (
        <div   className="screen_thirty"><div className="pagination_div"><p className="pagination_style">30</p></div>
            <img className="page3_style" src={page30}></img> 
        </div>
    )
}

export default ScreenThirty
