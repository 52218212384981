import React from 'react'
import '../Styles/ScreenFour.css';
import page4 from '../../Assets/background/page4-01.svg';

function ScreenFour() {
    return (
        <div className="screen_four" ><div className="pagination_div"><p className="pagination_style">4</p></div>
             <img className="page4_style" src={page4}></img> 
        </div>
    )
}

export default ScreenFour