import React from 'react'
import '../Styles/ScreenThree.css'
import page3 from '../../Assets/background/pagenew3-01.svg';

function ScreenThree() {
    return (
        <div  className="screen_three" ><div className="pagination_div"><p className="pagination_style">3</p></div>
            <img style={{ width:'90vw'}} className="page3_style" src={page3}></img> 
        </div>
    )
}

export default ScreenThree