import React from 'react'
import '../Styles/ScreenSeven.css'
import vid from '../../Assets/mp4/page7.mp4';
import loaderIcon from '../../Assets/loader/loaderBg-02.svg';

function ScreenSeven() {
	const vidRef = React.useRef(null)
		const playVid = () => {
			vidRef.current.play();
		}
    return (
        <div  className="screen_seven"><div className="pagination_div"><p className="pagination_style">8</p></div>
			<video poster={loaderIcon} autoPlay={true} playsInline muted autoStart={true} ref={vidRef} onEnded={(vidd) => {vidd.target.currentTime=5; playVid()}} allow="autoplay" style={{width:'90vw'}}>
				<source src={vid} autoPlay={true} autoStart={true} type="video/mp4"/>
		    </video>
        </div>
    )
}

export default ScreenSeven
